import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackComponent from "../../../../components/BackComponent/BackComponent";
import Modal from "../../../../components/Modal/Modal";
import { useUserState } from "../../../../context/UserContext";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { IndividualneProgramskeTerapijeValidationSchema } from "./Schema";
import styles from "./Styles.module.css";
import {
  brojTerapije,
  handleInputChange,
  handleUnesiSateClick,
  postData,
} from "./utils";

function IndividualneProgramskeTerapije({ terapeuti, userEvidentionID }) {
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const { userData } = useUserState();
  const [showModal, setShowModal] = useState(false);
  const fullName = localStorage.getItem("evidentionFullName");
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  const [formData, setFormData] = useState({
    date: new Date().toISOString().split("T")[0],
    brojSati: "",
    fullName: "",
    therapistId: "",
    vanjskiTerapeut: "",
  });
  useEffect(() => {
    if (formData.fullName !== "Vanjski terapeut") {
      setFormData({
        ...formData,
        vanjskiTerapeut: "",
      });
    }
  }, [formData.fullName]);
  const otherInfo = JSON.stringify(postData("therapy_manual", formData));
  const [errors, setErrors] = useState({});

  return (
    <div className={styles.wrapper}>
      <BackComponent />
      <span className={styles.title}>
        Ručni unos sati za individualne programske terapije
      </span>
      <div className={styles.inputWrapper}>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Datum terapije</span>
          <input
            className={styles.rucniUnosInput}
            type="date"
            name="date"
            value={formData.date}
            onChange={(e) =>
              handleInputChange("date", e.target.value, setFormData, formData)
            }
          />
          {errors.date && <div className={styles.error}>{errors.date}</div>}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Broj sati u terminu terapije</span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="brojSati"
            value={formData.brojSati}
            onChange={(e) =>
              handleInputChange(
                "brojSati",
                e.target.value,
                setFormData,
                formData
              )
            }
          >
            <option value="" disabled>
              Odaberi
            </option>
            {brojTerapije.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.brojSati && (
            <div className={styles.error}>{errors.brojSati}</div>
          )}
        </div>
        <div className={styles.inputColumn}>
          <span className={styles.label}>Terapeut</span>
          <select
            className={styles.rucniUnosSelect}
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={(e) => {
              handleInputChange(
                "fullName",
                e.target.value,
                setFormData,
                formData,
                terapeuti
              );
            }}
          >
            <option value="" disabled>
              Odaberi
            </option>
            {terapeuti.map((item, index) => (
              <option key={index} value={item.label}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.fullName && (
            <div className={styles.error}>{errors.fullName}</div>
          )}
        </div>
        {formData.fullName === "Vanjski terapeut" && (
          <div className={styles.inputColumn}>
            <span className={styles.label}>
              Ime i prezime vanjskog terapeuta
            </span>
            <input
              className={styles.rucniUnosInput}
              type="text"
              name="vanjskiTerapeut"
              placeholder="Upiši"
              value={formData.vanjskiTerapeut}
              onChange={(e) =>
                handleInputChange(
                  "vanjskiTerapeut",
                  e.target.value,
                  setFormData,
                  formData
                )
              }
            />
            {errors.vanjskiTerapeut && (
              <div className={styles.error}>{errors.vanjskiTerapeut}</div>
            )}
          </div>
        )}
      </div>
      <div className={styles.btnRow}>
        <button
          onClick={() => {
            localStorage.setItem("evidentionFullName", fullName);
            localStorage.setItem("evidentionUserID", userEvidentionID);
            navigate("/evidencija");
          }}
          className={styles.cancelBtn}
        >
          Odustani
        </button>
        <button
          className={styles.successBtn}
          onClick={() =>
            handleUnesiSateClick(
              setErrors,
              formData,
              IndividualneProgramskeTerapijeValidationSchema,
              userEvidentionID,
              "therapy_manual",
              otherInfo,
              authToken,
              setModalContent,
              navigate,
              setShowModal,
              userData
            )
          }
        >
          Unesi sate
        </button>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          title={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
    </div>
  );
}

export default IndividualneProgramskeTerapije;
