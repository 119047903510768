import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getUsersNotifications = async (userId, accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/notifications?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting notifications: ", error);
    throw error;
  }
};
