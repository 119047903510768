import Success from "../../../../assets/img/ModalCheckmark.png";
import { postRucniUnosSati } from "../../api/postRucniUnosSati";

export const brojTerapije = [
  { label: 1, value: 1 },
  {
    label: 1.5,
    value: 1.5,
  },
  {
    label: 2,
    value: 2,
  },
];
export const intenzivi = [
  { label: "Ljetni intenzivi", value: "ljetni" },
  {
    label: "Vikend inteznivi",
    value: "vikend",
  },
];
export const LjetniIntenzivi = [
  {
    label: "Životnost",
    value: "zivotnost",
  },
  {
    label: "Granice",
    value: "granice",
  },
  {
    label: "Kontakt",
    value: "kontakt",
  },
  {
    label: "Užitak",
    value: "uzitak",
  },
  {
    label: "Seksualnost",
    value: "seksualnost",
  },
  {
    label: "Zaroni u sebe",
    value: "zaroniUSebe",
  },
  {
    label: "Flow and Stagnation",
    value: "flowAndStagnation",
  },
  {
    label: "From freeze to Vitality",
    value: "fromfreezeToVitality",
  },
];
export const VikendIntenzivi = [
  {
    label: "Povezanost/navezanost",
    value: "povezanost",
  },
  {
    label: "Prepuštanje",
    value: "prepustanje",
  },
  {
    label: "Namjera",
    value: "namjera",
  },
  {
    label: "Svrha",
    value: "svrha",
  },
  {
    label: "Zahvalnost",
    value: "zahvalnost",
  },
];

export const satiGPR = [
  { label: 25, value: 25 },
  {
    label: 13,
    value: 13,
  },
];

export const grupneSupervizije = [
  { label: "Trijade", value: "trijada" },
  { label: "EPeR", value: "eper" },
  { label: "AGS", value: "ags" },
];
export const tipUloge = [
  { label: "Terapeut", value: "terapeut" },
  { label: "Supervizant", value: "supervizant" },
  { label: "Sudionik", value: "sudionik" },
];

export const returnUloga = (tipGrupneSupervizije) => {
  let array = [];
  if (tipGrupneSupervizije === "trijada") {
    array = [
      { label: "Terapeut", value: "terapeut" },
      { label: "Sudionik", value: "sudionik" },
    ];
  } else if (tipGrupneSupervizije === "eper") {
    array = [{ label: "Sudionik", value: "sudionik" }];
  } else if (tipGrupneSupervizije === "ags") {
    array = [
      { label: "Supervizant", value: "supervizant" },
      { label: "Sudionik", value: "sudionik" },
    ];
  }
  return array;
};

export const satiPromatraca = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 1.5, value: 1.5 },
  { label: 2, value: 2 },
  { label: 2.5, value: 2.5 },
  { label: 3, value: 3 },
];

export const GPRSati = [
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 12,
    value: 12,
  },
  {
    label: 25,
    value: 25,
  },
];

export const uloge = [
  {
    label: "Asistent",
    value: "asistent",
  },
  {
    label: "Edukator",
    value: "edukator",
  },
  {
    label: "Edukant",
    value: "edukant",
  },
];
export const validateForm = async (setErrors, formData, schema) => {
  try {
    await schema.validate(formData, {
      abortEarly: false,
    });
    setErrors({});

    return true;
  } catch (error) {
    const newErrors = {};
    error.inner.forEach((err) => {
      newErrors[err.path] = err.message;
    });
    setErrors(newErrors);
    return false;
  }
};

export const postData = (eventType, formData) => {
  switch (eventType) {
    case "therapy_manual":
      return {
        date: formData.date,
        brojSati: formData.brojSati,
        fullName: formData.fullName,
        vanjskiTerapeut: formData.vanjskiTerapeut,
        therapistId: formData.therapistId,
      };

    case "facultative_workshop":
      return {
        fullName: formData.fullName,
        nazivRadionice: formData.nazivRadionice,
        date: formData.date,
        gprSati: formData.gprSati,
      };
    case "intensive":
      return {
        vrstaIntenziva: formData.vrstaIntenziva,
        name: formData.name,
        year: formData.year,
        gprSati: formData.gprSati,
      };
    case "ags":
    case "eper":
    case "trijada":
      return {
        tipGrupneSupervizije:
          formData.uloga === "sudionik"
            ? formData.tipGrupneSupervizije
            : formData.tipGrupneSupervizije === "trijada"
            ? "trijada_therapist"
            : formData.tipGrupneSupervizije === "ags"
            ? "ags_supervisant"
            : formData.tipGrupneSupervizije,
        date: formData.date,
        uloga: formData.uloga,
        satiIndividualneSupervizije: formData.satiIndividualneSupervizije,
        satiGrupneSupervizije: formData.satiGrupneSupervizije,
        fullName: formData.fullName,
        vanjskiSupervizor: formData.vanjskiSupervizor,
      };
    case "individual_supervision":
      return {
        date: formData.date,
        brojSati: formData.brojSati,
        fullName: formData.fullName,
        vanjskiSupervizor: formData.vanjskiSupervizor,
      };
    case "manual_work_with_client":
      return {
        fullName: formData.fullName,
        date: formData.date,
        year: formData.year ? formData.year : "",
        brojSati: formData.brojSati ? formData.brojSati : "",
        clientId: formData.clientId ? formData.clientId : "",
      };
    case "therapist_therapy_manual":
      return {
        vrstaRadionicePrograma: formData.vrstaRadionicePrograma,
        uloga: formData.uloga,
        dateGrupa: formData.dateGrupa,
        brojSati: formData.brojSati,
      };
    default:
      return [];
  }
};
export const handleUnesiSateClick = async (
  setErrors,
  formData,
  schema,
  userEvidentionID,
  eventType,
  otherInfo,
  accessToken,
  setModalContent,
  navigate,
  setShowModal,
  userData
) => {
  let manualWork = "none";
  const validate = await validateForm(setErrors, formData, schema);
  if (validate) {
    const post = await postRucniUnosSati(
      userEvidentionID,
      eventType,
      otherInfo,
      accessToken
    );
    if (eventType === "therapy_manual") {
      const othInfo = {
        date: JSON.parse(otherInfo).date,
        brojSati: JSON.parse(otherInfo).brojSati,
        fullName: userData?.firstName + " " + userData?.lastName,
        clientId: userData?.id,
      };
      manualWork = await postRucniUnosSati(
        JSON.parse(otherInfo).therapistId,
        "manual_work_with_client",
        JSON.stringify(othInfo),
        accessToken
      );
    }
    if (eventType === "manual_work_with_client") {
      const othInfo = {
        date: JSON.parse(otherInfo).date,
        brojSati: JSON.parse(otherInfo).brojSati,
        fullName: userData?.firstName + " " + userData?.lastName,
        therapistId: userData?.id,
      };
      manualWork = await postRucniUnosSati(
        JSON.parse(otherInfo).clientId,
        "therapy_manual",
        JSON.stringify(othInfo),
        accessToken
      );
    }
    if (
      post.status === 200 &&
      (manualWork === "none" || manualWork.status === 200)
    ) {
      setModalContent({
        image: Success,
        description: "Tvoji sati su uspješno uneseni!",
        buttons: [
          {
            label: "U redu",
            action: () => navigate("/evidencija"),
            className: "modal-btn-success",
          },
        ],
      });
      setShowModal(true);
    }
  }
};

export const handleInputChange = (
  name,
  value,
  setFormData,
  formData,
  terapeuti
) => {
  if (name === "fullName" && terapeuti) {
    const therapeutId = terapeuti.find((terapeut) => terapeut.label === value);
    setFormData((prevValues) => ({
      ...formData,
      [name]: value,
      therapistId: therapeutId.value,
    }));
  } else {
    setFormData((prevValues) => ({
      ...formData,
      [name]: value,
    }));
  }
};
export const previousAcademicYears = [
  {
    label: "2019/2020",
    value: "2019/2020",
  },
  {
    label: "2020/2021",
    value: "2020/2021",
  },
  {
    label: "2021/2022",
    value: "2021/2022",
  },
  {
    label: "2022/2023",
    value: "2022/2023",
  },
];
export const osobniRadGrupeRadionice = [
  {
    label: "Godina OR-a/eOR-a - 69 sati",
    value: "godina_OR",
  },
  {
    label: "RAR/eRAR - 21 sat",
    value: "godina_RAR",
  },
  {
    label: "Ljetni intenziv - 25 sati",
    value: "asistiranjeLjetniIPDIntenziv",
  },
  {
    label: "Modul uživo eOR-a - 25 sati",
    value: "asistiranjeModulUzivo_eOR",
  },
  {
    label: "Vikend intenziv - 13 sati",
    value: "asistiranjeVikendIntenzivi",
  },
  {
    label: "Fakultativna radionica - 3 sata",
    value: "asistiranjeFakultativneRadionice",
  },
];
