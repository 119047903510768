import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import SingleNotification from "../../components/SingleNotification/SingleNotification";
import { useUserState } from "../../context/UserContext";
import { useAuthentication } from "../hooks/useAuthentication";
import { getUsersNotifications } from "./api/getUsersNotifications";
import styles from "./PrimljeneObavijesti.module.css";
import { organizeNotificationsByMonth } from "./utils";

function PrimljeneObavijesti() {
  const navigate = useNavigate();
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const [currentPage, setCurrentPage] = useState(1);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const response = await getUsersNotifications(userData?.id, authToken);
      setNotifications(response);
    };
    if (authToken) fetchNotifications();
  }, [authToken]);

  let unreadNotifications;
  let readNotifications;
  let currentOffers;
  let itemsPerPage;
  let readNotificationsArray;

  if (notifications?.length > 0) {
    unreadNotifications = notifications?.filter(
      (item) => !item?.readBy?.includes(userData?.id)
    );
    readNotifications = notifications?.filter((item) =>
      item?.readBy?.includes(userData?.id)
    );
    itemsPerPage = 1;
    const readNotificationsByMonth =
      organizeNotificationsByMonth(readNotifications);
    readNotificationsArray = Object.entries(readNotificationsByMonth).map(
      ([monthYearKey, notifications]) => {
        const [month, year] = monthYearKey.split("-");
        return { month, year, notifications };
      }
    );

    currentOffers = readNotificationsArray.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
    if (readNotifications?.length === 0 && unreadNotifications?.length === 0) {
      return (
        <div className={styles.emptyContainer}>
          <span className={styles.emptySpan}>
            Još uvijek nemaš obavijesti.{" "}
          </span>
        </div>
      );
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.topTitle}>
        <div className={styles.horizontal} />
        NEPROČITANO
        <div className={styles.horizontal} />
      </div>
      <div className={styles.notificationColumn}>
        {unreadNotifications?.length > 0 ? (
          unreadNotifications?.map((item, index) => (
            <SingleNotification
              onClick={() => navigate(`/pojedina-obavijest/${item.id}`)}
              key={index}
              isRead={item.readBy?.includes(userData?.id)}
              createdAt={item.createdAt}
              fullName={item.fullName}
              notificationTitle={item.subject}
              showDate
              showBorder={index !== unreadNotifications?.length - 1}
            />
          ))
        ) : (
          <p style={{ width: "100%", textAlign: "center" }}>
            Trenutno nemate nepročitanih obavijesti!
          </p>
        )}
      </div>
      {currentOffers?.map((item, index) => (
        <div className={styles.readColumn} key={index}>
          <div className={styles.topTitle} style={{ marginBottom: 24 }}>
            <div className={styles.horizontal} />
            <div className={styles.date}>
              {item.month} {item.year}
            </div>
            <div className={styles.horizontal} />
          </div>
          {item.notifications?.map((notification, notificationIndex) => (
            <SingleNotification
              onClick={() => navigate(`/pojedina-obavijest/${notification.id}`)}
              key={notificationIndex}
              isRead={notification?.readBy?.includes(userData?.id)}
              createdAt={notification?.createdAt}
              fullName={notification?.fullName}
              notificationTitle={notification?.subject}
              showDate
              showBorder={notificationIndex !== item.notifications?.length - 1}
            />
          ))}
        </div>
      ))}
      <Pagination
        itemsToDisplay={readNotificationsArray}
        setCurrent={setCurrentPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        customWidth={"100%"}
      />
    </div>
  );
}

export default PrimljeneObavijesti;
