export const PrimateljData = [
  { label: "Polaznici", value: 1 },
  { label: "Grupa", value: 2 },
  { label: "Program", value: 3 },
  { label: "Djelatnici", value: 4 },
  { label: "Zakupitelji soba", value: 6 },
  { label: "GPR", value: 7 },
];

export const GprData = [
  { label: "Fakultativne radionice", value: 1 },
  { label: "Intenzivi", value: 2 },
];

export const RoleData = [
  { label: "Terapeuti", value: 3 },
  { label: "Voditelji", value: 4 },
  { label: "Supervizori", value: 5 },
  { label: "Referada", value: 6 },
  { label: "Učitelji", value: 7 },
  { label: "Polaznici VT-a", value: 8 },
];

export const SlanjeObavijestiData = {
  PrimateljData,
  GprData,
};
