import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const updateIsRead = async (notificationId, accessToken) => {
  try {
    const response = await axios.put(
      `${apiUrl}/api/notification?id=${notificationId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating notification: ", error);
    throw error;
  }
};
