import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const sendNotifications = async (notification, accessToken) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/notification`,
      notification,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating notification: ", error);
    return error;
  }
};
