import React from "react";
import Anon from "../../../../../assets/img/Anonymous.png";
import Plus from "../../../../../assets/svg/AddIcon.svg";
import { useUserState } from "../../../../../context/UserContext";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import { formatDate } from "../../../../myProfile/utils";
import { addSuglasnost } from "./api/addSuglasnost";
import RenderTableHeader from "./RenderTableHeader";
import "./Table.css";

function RenderTable({ data, role, evidentionUserID, change, setChange }) {
  const columnNames = ["Datum", "Supervizor"];
  const { userData } = useUserState();
  const { authToken } = useAuthentication();

  const handleAddSuglasnost = async () => {
    if (role === "Supervizor" || role === "Voditelj") {
      await addSuglasnost(evidentionUserID, userData.id, authToken);
      setChange(!change);
    }
  };

  return (
    <div style={{ width: "30%", marginTop: 6, marginBottom: 24 }}>
      {data?.length === 0 && role !== "Supervizor" && role !== "Voditelj" ? (
        <p className="no-data">Trenutno nemate suglasnosti</p>
      ) : data?.length === 0 &&
        (role === "Supervizor" || role === "Voditelj") ? (
        <table className="table-supervizije-suglasnost">
          <thead>
            <RenderTableHeader columnNames={["Suglasnosti"]} />
          </thead>
          <tbody>
            <div
              className="table-supervizije-suglasnost-row dodaj-suglasnost"
              onClick={() => handleAddSuglasnost()}
            >
              <img
                alt=""
                className="table-supervizije-suglasnost-avatar"
                src={Plus}
              />
              Ostavi suglasnost
            </div>
          </tbody>
        </table>
      ) : data?.length === 1 &&
        (role === "Supervizor" || role === "Voditelj") ? (
        <table className="table-supervizije-suglasnost">
          <thead>
            <RenderTableHeader columnNames={columnNames} />
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td>{item?.createdAt ? formatDate(item?.createdAt) : "/"}</td>
                <td>
                  <div className="table-supervizije-suglasnost-row">
                    <img
                      alt=""
                      className="table-supervizije-suglasnost-avatar"
                      src={item?.imageUrl ? item?.imageUrl : Anon}
                    />
                    {item?.fullName ? item?.fullName : "/"}
                  </div>
                </td>
              </tr>
            ))}
            {data[0].supervisorId !== userData.id && (
              <div className="table-supervizije-suglasnost-row dodaj-suglasnost">
                <img
                  alt=""
                  className="table-supervizije-suglasnost-avatar"
                  src={Plus}
                />
                Ostavi suglasnost
              </div>
            )}
          </tbody>
        </table>
      ) : (
        <table className="table-supervizije-suglasnost">
          <thead>
            <RenderTableHeader columnNames={columnNames} />
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td>{item?.createdAt ? formatDate(item?.createdAt) : "/"}</td>
                <td>
                  <div className="table-supervizije-suglasnost-row">
                    <img
                      alt=""
                      className="table-supervizije-suglasnost-avatar"
                      src={item?.imageUrl ? item?.imageUrl : Anon}
                    />
                    {item?.fullName ? item?.fullName : "/"}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default RenderTable;
