import React, { useState } from "react";
import ArrowDown from "../../../../assets/img/ArrowDown.png";
import InfoCard from "../../../../assets/img/infoCircleCard.png";
import Pagination from "../../../../components/Pagination/Pagination";
import EvaluacijeTable from "../Supervizije/Accordions/EvaluacijeTable/EvaluacijeTable";
import AccordionItems from "./AccordionItems";
import "./CardAccordion.css";

const CardAccordion = ({
  title,
  modules,
  totalAttendance,
  variant,
  isDisabled,
  infoText,
  attendedCount,
  onTopicNameClick,
  showInfoText,
  userId,
  isNameClickable,
  isInfoTextClickable,
  isRar,
  isOR,
  isTP,
  isTT,
  showItems = true,
  columnNames,
  tableData,
  isInteraktivnaPredavanja,
  showEditBtn,
  maxTopObracun,
  maxBottomObracun,
  errorFlag,
  showModulName = true,
  evidentionUserID,
  setEvidentionData,
  setDeleteFlag,
  deleteFlag,
  change,
  setChange,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOffers = tableData?.slice(indexOfFirstItem, indexOfLastItem);
  const [isOpen, setIsOpen] = useState(false);
  const [activeModuleIndex, setActiveModuleIndex] = useState(0);
  const [showButtons, setShowButtons] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const headerOpis = variant === "hours" ? "Opis" : "Tema";
  const headerHrs = variant === "hours" ? "Ukupno sati" : "Dolazak";
  const totalText = variant === "hours" ? "Sveukupno (h)" : "UKUPNO DOLAZAKA: ";

  const isHoursVariant = variant === "hours";
  let totalAttendanceClass = "total-attendance";
  const conditionalStyle = showInfoText
    ? {}
    : { borderBottomRightRadius: 10, borderBottomLeftRadius: 10 };

  if (isHoursVariant) {
    const maxNumber = maxTopObracun || maxBottomObracun;
    if (attendedCount < maxNumber) {
      totalAttendanceClass = "error-attendance";
    }
  } else {
    totalAttendanceClass = errorFlag ? "error-attendance" : "total-attendance";
  }

  const handleModuleClick = (index) => {
    setActiveModuleIndex(index);
  };
  const handleEditBtnClick = () => {
    setIsOpen(true);
    setShowButtons(true);
  };

  return (
    <>
      <div
        className="card-accordion-main"
        style={{
          borderColor: isTT
            ? "rgb(140 0 0)"
            : isTP
            ? "rgb(215 60 60)"
            : isOR
            ? "rgb(219 184 115)"
            : isRar
            ? "rgb(109 183 44)"
            : null,
        }}
      >
        <div
          className={`container-accordion-main ${isOpen ? "" : "closed"} ${
            isDisabled ? "disabledAccordion" : ""
          }`}
        >
          <div className="accordionRow" onClick={toggleAccordion}>
            <h3 className="title-accordion-main">{title}</h3>
            <img src={ArrowDown} className={isOpen ? "rotated" : ""} />
          </div>
        </div>
        {isOpen && (
          <>
            {showModulName && modules && (
              <div className="module-tabs">
                {modules?.map((mod, index) => (
                  <button
                    key={index}
                    onClick={() => handleModuleClick(index)}
                    className={`module-button ${
                      index === activeModuleIndex ? "active" : ""
                    }`}
                  >
                    {index === 0 && isInteraktivnaPredavanja
                      ? "Predavanja"
                      : index > 0 && isInteraktivnaPredavanja
                      ? `Modul ${index}`
                      : `Modul ${index + 1}`}
                  </button>
                ))}
              </div>
            )}
            {showItems ? (
              <>
                <div className="container-header-card-accordion">
                  <button className="header-btn-add">
                    <span className="Tema-btn-add">{headerOpis}</span>
                  </button>
                  <button className="Dolazak-btn-add">
                    <span>{headerHrs}</span>
                  </button>
                </div>
                {modules && modules[activeModuleIndex] && (
                  <ul className="list-parent-accordion-card">
                    {modules[activeModuleIndex]
                      ?.sort((a, b) => a.id - b.id)
                      ?.sort((a, b) => a?.place - b?.place)
                      ?.map((module, index) => {
                        return (
                          <AccordionItems
                            evidentionUserID={evidentionUserID}
                            key={module.id}
                            topic={module}
                            variant={variant}
                            isInteraktivnoPredavanje={
                              modules[activeModuleIndex]?.name ===
                                "Interaktivna predavanja" &&
                              userId === "Polaznik"
                            }
                            isChecked={module?.attendance}
                            setEvidentionData={setEvidentionData}
                            isRar={isRar}
                            isOR={isOR}
                            isTP={isTP}
                            isTT={isTT}
                            // isEdit={showButtons && userId === "Referada"}
                            onTopicNameClick={onTopicNameClick}
                            isNameClickable={isNameClickable}
                            userId={userId}
                            setDeleteFlag={setDeleteFlag}
                            deleteFlag={deleteFlag}
                          />
                        );
                      })}
                  </ul>
                )}
              </>
            ) : (
              <div className="evaluacije-table-container">
                <EvaluacijeTable
                  columnNames={columnNames}
                  data={currentOffers}
                  evidentionUserID={evidentionUserID}
                  change={change}
                  setChange={setChange}
                />
                <div className="paginationContainer">
                  <Pagination
                    itemsToDisplay={tableData}
                    setCurrent={setCurrentPage}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                  />
                </div>
              </div>
            )}
            {showItems && (
              <>
                {userId !== "polaznikRAR" && (
                  <div
                    className={totalAttendanceClass}
                    style={conditionalStyle}
                  >
                    <span className="total-text">{totalText}</span>
                    <span className="ukupni-dolazak">{totalAttendance}</span>
                  </div>
                )}
              </>
            )}
            {showInfoText && (
              <footer className="footer-card-accordion">
                <p className="bottom-foother-text-card">
                  {isInfoTextClickable ? (
                    <span className="clickable-info-text">{infoText}</span>
                  ) : (
                    <div className="info-container">
                      <img src={InfoCard} />
                      {infoText}
                    </div>
                  )}
                </p>
              </footer>
            )}
          </>
        )}
      </div>
      {showButtons && (
        <div className="accordionBtnRow">
          <button
            className="accordionCancelBtn"
            onClick={() => setShowButtons(false)}
          >
            Odustani
          </button>
          <button className="accordionSaveBtn">Spremi promjene</button>
        </div>
      )}
    </>
  );
};

export default CardAccordion;
