import React, { useEffect, useState } from "react";
import { formatDate } from "../../scenes/myProfile/utils";
import styles from "./SingleNotification.module.css";

function SingleNotification({
  fullName,
  notificationTitle,
  createdAt,
  isRead,
  onClick,
  showDate,
  showBorder,
}) {
  const [timePassed, setTimePassed] = useState("");
  const [displayTitle, setDisplayTitle] = useState("");

  useEffect(() => {
    const postedTime = new Date(createdAt);
    const currentTime = new Date();
    const timeDifference = currentTime - postedTime;
    const hoursPassed = Math.floor(timeDifference / (1000 * 60 * 60));
    if (hoursPassed < 24) {
      setTimePassed(`${hoursPassed}h`);
    } else if (hoursPassed > 24 && hoursPassed < 48) {
      setTimePassed(`Jučer`);
    } else if (hoursPassed > 48) {
      const days = Math.floor(hoursPassed / 24);
      setTimePassed(`Prije ${days} dana`);
    }
  }, [createdAt]);

  useEffect(() => {
    const truncatedTitle =
      notificationTitle?.length > 56
        ? notificationTitle?.slice(0, 53) + "..."
        : notificationTitle;

    setDisplayTitle(truncatedTitle);
  }, [notificationTitle]);
  return (
    <div
      className={styles.notification}
      onClick={onClick}
      style={{ borderBottom: showBorder ? "1px solid #d1d9e2" : "" }}
    >
      <div className={styles.leftSide}>
        <span className={notificationTitle}>
          <span style={{ fontWeight: 600, fontSize: 16 }}>{fullName} </span>
          {displayTitle}
        </span>
        <span className={styles.time}> {timePassed}</span>
      </div>
      <div className={styles.rightSide}>
        {showDate && (
          <span className={styles.date}>{formatDate(createdAt)}</span>
        )}
        {!isRead && <div className={styles.pill}>NOVO</div>}
      </div>
    </div>
  );
}

export default SingleNotification;
