import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const addSuglasnost = async (userId, supervisorId, authToken) => {
  try {
    await axios.post(
      `${apiUrl}/api/supervisorResponse`,
      {
        userId: userId,
        supervisorId: supervisorId,
        type: "consent",
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  } catch (error) {
    console.error("Error creating suglasnost", error);
    throw error;
  }
};
