import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getNotificationById = async (notificationId, accessToken) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/notification?id=${notificationId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting notification by id: ", error);
    return error;
  }
};
