export const Referada = [
  {
    icon: require("../../assets/img/HomeIcon.png"),
    title: "Početna",
    pathname: "/dashboard",
  },
  {
    icon: require("../../assets/img/Programs.png"),
    title: "Programi",
    pathname: "/programi/one",
  },
  {
    icon: require("../../assets/img/PolazniciIcon.png"),
    title: "Polaznici",
    pathname: "/polaznici",
  },
  {
    icon: require("../../assets/img/SidemenuGrupe.png"),
    title: "Grupe",
    pathname: "/grupe",
  },
  {
    icon: require("../../assets/img/SidemenuIPDTim.png"),
    title: "Školski tim",
    pathname: "/djelatnici",
  },
  {
    icon: require("../../assets/img/OsvrtiIcon.png"),
    title: "Osvrti na voditelje",
    pathname: "/osvrti/one",
  },
  {
    icon: require("../../assets/img/TerapeutiIcon.png"),
    title: "Terapeuti",
    pathname: "/terapeuti",
  },
  {
    icon: require("../../assets/img/PaymentIcon.png"),
    title: "Plaćanja i narudžbe",
    pathname: "/payment",
  },
  {
    icon: require("../../assets/img/DokumentiIcon.png"),
    title: "Dokumenti",
    pathname: "/dokumenti",
  },
  {
    icon: require("../../assets/img/KalendarIcon.png"),
    title: "Kalendar",
    pathname: "/kalendar",
  },
  {
    icon: require("../../assets/img/SobeIcon.png"),
    title: "Sobe",
    pathname: "/sobe",
  },
];
export const Polaznik = [
  {
    icon: require("../../assets/img/HomeIcon.png"),
    title: "Početna",
    pathname: "/dashboard",
  },
  {
    icon: require("../../assets/img/Programs.png"),
    title: "Programi",
    pathname: "/programi/one",
  },
  {
    icon: require("../../assets/img/Prijavnice.png"),
    title: "Prijavnice",
    pathname: "/prijavnice/one",
  },
  {
    icon: require("../../assets/img/SidemenuGrupe.png"),
    title: "Moje grupe",
    pathname: "/grupe",
  },
  {
    icon: require("../../assets/img/EvidencijaIcon.png"),
    title: "Moja evidencija",
    pathname: "/evidencija",
  },
  {
    icon: require("../../assets/img/DokumentiIcon.png"),
    title: "Dokumenti",
    pathname: "/dokumenti",
  },
  {
    icon: require("../../assets/img/KalendarIcon.png"),
    title: "Kalendar",
    pathname: "/kalendar",
  },
  {
    icon: require("../../assets/img/TerapeutiIcon.png"),
    title: "Terapeuti",
    pathname: "/terapeuti",
  },
  {
    icon: require("../../assets/img/OsvrtiIcon.png"),
    title: "Osvrti na voditelje",
    pathname: "/osvrti/one",
  },
  {
    icon: require("../../assets/img/SobeIcon.png"),
    title: "Korištenje soba",
    pathname: "/sobe",
    show: false,
  },
];
export const Voditelj = [
  {
    icon: require("../../assets/img/SobeIcon.png"),
    title: "Korištenje soba",
    pathname: "/sobe",
  },
  {
    icon: require("../../assets/img/SidemenuIPDTim.png"),
    title: "Školski tim",
    pathname: "../../assets/img/SidemenuIPDTim.png",
  },
  {
    icon: require("../../assets/img/DolasciIcon.png"),
    title: "Evidencija dolazaka",
    pathname: "/evidencija-dolazaka",
  },
  {
    icon: require("../../assets/img/EvidencijaPolazniciIcon.png"),
    title: "Evidencija polaznika",
    pathname: "/evidencijaVoditeljPolaznik",
  },
];

export const OstaleRole = [
  {
    icon: require("../../assets/img/HomeIcon.png"),
    title: "Početna",
    pathname: "/dashboard",
  },
  {
    icon: require("../../assets/img/Programs.png"),
    title: "Programi",
    pathname: "/programi/one",
  },
  {
    icon: require("../../assets/img/Prijavnice.png"),
    title: "Prijavnice",
    pathname: "/prijavnice/one",
  },
  {
    icon: require("../../assets/img/SidemenuGrupe.png"),
    title: "Moje grupe",
    pathname: "/grupe",
  },
  {
    icon: require("../../assets/img/EvidencijaIcon.png"),
    title: "Moja evidencija",
    pathname: "/evidencija",
  },
  {
    icon: require("../../assets/img/DokumentiIcon.png"),
    title: "Dokumenti",
    pathname: "/dokumenti",
  },
  {
    icon: require("../../assets/img/KalendarIcon.png"),
    title: "Kalendar",
    pathname: "/kalendar",
  },
  {
    icon: require("../../assets/img/TerapeutiIcon.png"),
    title: "Terapeuti",
    pathname: "/terapeuti",
  },
  {
    icon: require("../../assets/img/SobeIcon.png"),
    title: "Korištenje soba",
    pathname: "/sobe",
  },
  {
    icon: require("../../assets/img/SidemenuIPDTim.png"),
    title: "Školski tim",
    pathname: "../../assets/img/SidemenuIPDTim.png",
  },
  {
    icon: require("../../assets/img/DolasciIcon.png"),
    title: "Evidencija dolazaka",
    pathname: "/evidencija-dolazaka",
  },
  {
    icon: require("../../assets/img/EvidencijaPolazniciIcon.png"),
    title: "Evidencija polaznika",
    pathname: "/evidencijaVoditeljPolaznik",
  },
];
