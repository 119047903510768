import React from "react";
import CustomHeader from "../../components/CustomHeader/CustomHeader";
import { useUserState } from "../../context/UserContext";
import MojObracunKoristenjaSoba from "./MojObracunKoristenjaSoba";
import styles from "./ObracunSoba.module.css";
import PotpuniObracunKoristenjaSoba from "./PotpuniObracunKoristenjaSoba";
import PregledKoristenjaSoba from "./PregledKoristenjaSoba";
import PregledPotrosnjePoGradovima from "./PregledPotrosnjePoGradovima";
import PregledTotalnogObracunaZaSobu from "./PregledTotalnogObracunaZaSobu";

function ObracunSoba() {
  const { role } = useUserState();
  return (
    <>
      <CustomHeader title={"Obračun soba"} />
      <div className={styles.wrapper}>
        <MojObracunKoristenjaSoba />
        {role !== "Voditelj" && (
          <>
            <PregledKoristenjaSoba />
            <PotpuniObracunKoristenjaSoba />
            <PregledTotalnogObracunaZaSobu />
            <PregledPotrosnjePoGradovima />
          </>
        )}
      </div>
    </>
  );
}

export default ObracunSoba;
